import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './App.css'; // Importing the separate css file
import { isSameDay } from "date-fns";


// Import the kitten image
import kittenImage from './kitten.jpg';

function App() {
  // Define the background style
  const backgroundStyle = {
    backgroundImage: `url(${kittenImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const handleDayClick = async (value) => {
    const year = value.getFullYear();
    const month = value.getMonth() + 1; // Months are zero-based
    const day = value.getDate();

    try {
      const response = await fetch('/api/select-day', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ year, month, day }),
      });

      const data = await response.json();

      if (data.message) {
        alert(data.message);
      }

      if (data.link) {
        window.location.href = data.link;
      }
    } catch (error) {
      alert('Error while fetching data from the API');
    }
  };
  const getTileContent = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      if (isSameDay(date, today)) {
        console.log('Date today is ', date);
        return <div>Today!</div>;
      }
    }
    return null;
  };

  return (
    <div style={backgroundStyle}>
      <Calendar calendarType="gregory"
          tileContent={getTileContent}
          onClickDay={handleDayClick} />
    </div>
  );
}

export default App;
